.tags-options {
    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: #80808038 !important ;
    }

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
        .ant-select-item-option-state {
        color: #4d9562 !important;
    }
}

.select-tags {
    .ant-transfer-list {
        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #1dbf73 !important ;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: #1dbf73 !important;
        }

        .ant-checkbox-checked::after {
            border-color: #1dbf73 !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #1dbf73 !important;
            border-color: #1dbf73 !important;
        }

        .ant-transfer-list-content {
            &::-webkit-scrollbar {
                display: none !important;
            }
        }

        .ant-transfer-list-body {
            .ant-transfer-list-content-show-remove {
                .ant-transfer-list-content-item {
                    margin: 5px 10px;
                    border-radius: 12px;

                    .ant-transfer-list-content-item-remove {
                        &:hover {
                            color: #f06a6a !important;
                        }
                    }
                }
            }
        }
    }

    .ant-transfer-operation {
        .ant-btn {
            width: 92px;
            border-radius: 13px;
            height: 30px;
            text-align: center;

            .anticon-right {
                display: none !important;
            }

            .anticon-arrow-right {
                font-size: 15px;
                margin: 0;
            }
        }
    }
}

.review-room-modal {
    .ant-modal-content {
        width: 700px;
    }
}
