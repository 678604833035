@import '../../../../app/assets/styles/variables.scss';

.project-row {
    // border: 12px solid red;
    background-color: #e8ede8;
    padding: 55px 0;
}
.container {
    max-width: 1140px;
    margin: auto;
    padding: 0 25px;
}

.inner-row {
    flex-direction: column;
    h1 {
        color: $dark-text-color-primary;
        font-size: 2.75rem;
        font-weight: 600;
        span {
            color: $primary-color;
            font-weight: 900;
            font-size: 3.75rem;
        }
    }
}
.project-images {
    display: flex;
    justify-content: space-between;
    img {
        height: 100px;
    }
}
