$breakpoints: (
    'xs': 0,
    'sm': 480px,
    'md': 720px,
    'lg': 992px,
    'xl': 1200px,
    'xxl': 1254px,
);

@mixin xs {
    @media (min-width: map-get($breakpoints, 'xs')) {
        @content;
    }
}

@mixin sm {
    @media (min-width: map-get($breakpoints, 'sm')) {
        @content;
    }
}
@mixin md {
    @media (min-width: map-get($breakpoints, 'md')) {
        @content;
    }
}
@mixin lg {
    @media (min-width: map-get($breakpoints, 'lg')) {
        @content;
    }
}

@mixin xl {
    @media (min-width: map-get($breakpoints, 'xl')) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: map-get($breakpoints, 'xxl')) {
        @content;
    }
}

@mixin breakpoint($bp: 1653px) {
    @media (min-width: $bp) {
        @content;
    }
}

.responsive-goto-package-button {
    @include lg {
        left: 32%;
    }

    @include xl {
        left: 33%;
    }

    @include xxl {
        left: 37%;
    }

    @include breakpoint {
        left: 54.2%;
    }
}
