.main-body {
    max-height: fit-content;
    overflow-x: hidden;
    margin-bottom: 0;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    .user-status-card {
        margin: 10px;
    }

    .list-user-container {
        box-shadow: 0px 10px 5px rgb(226, 224, 224), 0px 1px 5px rgb(226, 224, 224);

        .left-side-container {
            height: 100vh;
            overflow-y: auto;
            font-size: 1.1rem;
        }
        .right-side-container {
            margin-left: 1.2rem;
            margin-right: 1.2em;
        }
    }
}
