.site-card-border-less-wrapper {
    padding: 10px;
    background: #ececec;
}

.kanban-priority-select-item {
    .ant-select-item-option-content {
        display: flex;
        align-items: center;

        span {
            display: block;
            margin: 0 0 3px 0;
        }

        img {
            height: 16px;
            width: 16px;
        }

        p {
            margin: 0 0 0 8px;
            // font-weight: 500;
            color: #000;
        }
    }
        
}


.kanban-priority-select {
    .ant-select-selection-item {
        display: flex;
        align-items: center;

        span {
            display: block;
            margin: 0 0 3px 0;
        }

        img {
            height: 16px;
            width: 16px;
        }

        p {
            margin: 0 0 0 6px;
            font-weight: 500;
            color: #000;
        }
    }
}

.pdf-viewer {
    max-height: 77vh;
    width: 72%;
    overflow: auto;
}
.card-viewer {
    height: 77vh;
    width: 34%;
    overflow: auto;
    padding: 0 15px 0 0;

    .ant-collapse {
        background-color: transparent;
        border: none;
    }

    .ant-collapse-item {
        background-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        box-shadow: 0 2px 1px rgba(0,0,0,0.09), 0 6px 6px rgba(0,0,0,0.03);
        margin: 0 0 16px 0;
    }
}

.card-select {
    width: 80px;
    color: #fff;

    .ant-select-arrow {
        color: #fff;
    }
}

.card-select-red {
    .ant-select-selector {
        background-color: #eb5a46 !important;
    }
}

.card-select-yellow {
    .ant-select-selector {
        background-color: #fc9f19 !important;
    }
}

.kanban-listing-header {
    border-bottom: 1px solid #f0f0f0;
    padding: 0 0 10px 0;
    margin: 0 0 16px 0;

    p {
        margin: 0;
    }
}

.kanban-item {
    > .ant-collapse-header {
        align-items: center !important;
    }
}

.kanban-detail-card {
    box-shadow: 0 10px 20px rgba(0,0,0,0.09), 0 6px 6px rgba(0,0,0,0.13);

    .ant-collapse-item {
        margin: 0;
    }
}

.card-select-green {
    .ant-select-selector {
        background-color: #61bd4f !important;
    }
}
.collapse-padding {
    .ant-collapse-content-box {
        padding: 6px 16px 24px 16px;
    }
}
.comment-list {
    width: 100%;
}
.slider-div {
    display: flex;
    font-size: 14px;
}

.ant-slider-step {
    background: lightgray;
    height: 2px;
}
.ant-slider-handle.ant-tooltip-open {
    border-color: lightgray;
}
.ant-slider-handle {
    border-radius: 0;
    border: solid 1px lightgray;
    width: 10px;
    height: 24px;
    margin-top: -9px;
}

.ant-slider-track {
    height: 2px;
}

.ant-slider-rail {
    height: 2px;
}

.ant-slider-dot-active,
.ant-slider-dot,
.ant-slider-track,
.ant-slider:hover .ant-slider-track {
    background-color: lightgray;
    border: 1px solid lightgray;
}
.ant-slider-dot-active {
    border-color: lightgray;
}
.ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.45);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: lightgray;
}
.ant-slider-handle:focus {
    border-color: lightgray;
    box-shadow: none;
}
