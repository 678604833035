.search-box {
    .ant-input {
        width: 100%;
        background-color: #f1f4f6 !important;
        padding-left: 32px;
        height: 38px;
        font-size: 14px;
        color: #555;
        background: url('../../assets/images/search-icon.png') no-repeat center left 10px;
        background-size: 14px;
        border: 0;
        border-radius: 4px;

        &:hover,
        &:focus {
            border-color: #16aaff;
            box-shadow: none;
        }
    }
}
