.right-data-container {
    margin-top: 0.4rem;

    h2 {
        font-family: Roboto, 'Segoe UI', Tahoma, sans-serif;
        font-size: 1.3rem;
        letter-spacing: 0.25px;
        margin-bottom: 12px;
        margin-left: 12px;
    }
}
