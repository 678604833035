.status-body {
    .content-body {
        background-color: #fff;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 1rem;
        font-weight: 500;
        height: 5rem;
        width: 17rem;
        margin: auto;
        padding: 22px;
        border-radius: 10px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
}
