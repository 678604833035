@import '../../../assets/styles/variables.scss';

.sline-card {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
        0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
        0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

    .ant-card-cover {
        margin: 0;
        height: 200px;
        border-bottom: 1px solid #d7d7d7;

        img {
            height: 100%;
            object-fit: contain;
            background-color: #fff;
        }
    }

    .ant-card-body {
        background-color: $dark-blue-color;
        text-align: center;
        padding: 15px;
    }

    .ant-card-meta-title {
        color: #fff;
        font-weight: 500;
    }
}
