.card_body {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .bread {
        display: flex;
        align-items: baseline;
        .dropdown {
            margin: 0;
        }
        .bread {
            margin-left: 10px;
        }
    }
    .topMenu {
        margin-bottom: 20px;
    }

    .topMenu {
        background-color: #f0f3f7;
    }
}

.arrow {
    cursor: pointer;
    text-align: center;
    display: inline-block;
    height: 40px;
    margin: 0;
    // margin-left: 40px;
    // margin-right: 40px;
    position: relative;
    line-height: 2.5em;
    width: 212px;
    padding-left: 1em;
    padding-right: 2em;
    background: #dee1e3;
    color: black;
    &:after {
        // triangle hover color
        border-left: 20px solid #dee1e3;
    }
}

.arrow:after {
    // the triangle
    content: '';
    position: absolute;
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    height: 0px;
    width: 0px;
    margin-right: -20px;
    right: 0;
}

.arrow:hover,
.arrow:active {
    background: #c6cfff;
    // color: white;
    font-weight: bold;
    text-align: center;
    &:after {
        // triangle hover
        border-left: 20px solid #c6cfff;
    }
}

.arrow-highlight {
    cursor: pointer;
    display: inline-block;
    height: 40px;
    font-weight: bold;
    text-align: center;
    // margin-left: 40px;
    // margin-right: 40px;
    width: 212px;

    position: relative;
    line-height: 2.5em;
    padding-left: 1em;
    padding-right: 2em;
    background: #c6cfff;
    // color: white;
    &:after {
        // triangle hover color
        border-left: 20px solid #c6cfff;
    }
}

.arrow-highlight:after {
    // the triangle
    content: '';
    position: absolute;
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    height: 0px;
    width: 0px;
    margin-right: -20px;
    right: 0;
}

.arrow-highlight:hover,
.arrow:active {
    background: #c6cfff;
    // color: white;
    &:after {
        // triangle hover
        border-left: 20px solid #c6cfff;
    }
}

// Approval Component
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.card {
    .file_upload_wrapper {
        position: relative;
        width: 100%;

        .ant-upload {
            display: block;
            opacity: 0;
        }

        Input {
            cursor: pointer;
            border: none;
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1;
            margin: 0;
            display: block;
            padding: 0;
            width: 100%;
            height: 31px;
            border-radius: 0 15px 15px 0;
        }

        &::after {
            content: 'Select your file';
            font-size: 15px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #f3f8ff;
            padding: 10px 15px;
            width: 100%;
            pointer-events: none;
            z-index: 1;
            height: 30px;
            line-height: 15px;
            color: #999;
            font-weight: 300;
            border-radius: 0 15px 15px 0;
        }
        &::before {
            content: 'upload';
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 0;
            display: inline-block;
            height: 30px;
            background-color: #1890ff;
            color: white;
            font-weight: 700;
            z-index: 2;
            font-size: 12px;
            line-height: 30px;
            padding: 0 15px;
            text-transform: uppercase;
            pointer-events: none;
            border-radius: 0 15px 15px 0;
            transition: 0.5s ease-in-out;
        }
    }

    .uploaded_file {
        // margin-top: 60px;
        width: 100%;

        .child-upload {
            display: flex;
            margin-top: 10px;
            padding: 0;
            width: 100%;
            justify-content: space-around;
            align-items: baseline;
        }
    }

    .bottom-btn {
        display: flex;
        justify-content: right;
    }
}
.site-collapse-custom-panel {
    margin-bottom: 24px;
    overflow: hidden;
    background: #deecff;
    border: 0px;
    border-radius: 2px;
}

.site-collapse-custom-panel-grey {
    margin-bottom: 24px;
    overflow: hidden;
    background: #dee1e3;
    border: 0px;
    border-radius: 2px;

    .ant-table-row {
        vertical-align: top;
    }
}

.site-collapse-custom-panel-warning {
    margin-bottom: 24px;
    overflow: hidden;
    background: #ffccc7;
    border: 0px;
    border-radius: 2px;

    .ant-table-row {
        vertical-align: top;
    }
}

.site-collapse-custom-collapse {
    background: #f3f8ff;
}

.release-check-btn {
    font-size: 22px;
    color: green;
    cursor: pointer;
}

.main-trim-text {
    align-items: center;
    .trim-text {
        width: 140px;
        overflow: hidden;
        display: inline-block;
        margin-bottom: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.circle-icon {
    background-color: #c7cacf;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text {
    font-weight: 600;
}

.buttonView {
    background-color: #f3f8ff;
}

.center-icon {
    margin-left: 20px;
}

// Markup Component

.header {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    .icon {
        display: flex;
    }
}

.checkbox {
    margin: 15px;
    padding: 7px;
}
.menu-items {
    display: flex;
    align-items: center;
    border: 2px solid #deecff;
    border-radius: 25px;
    background-color: #f3f8ff;
    position: -webkit-sticky;
    position: sticky;
    height: 30px;
    top: 0;
    font-weight: 500;
    margin-bottom: 10px;

    p {
        margin: 0;
    }
}

.original-body {
    display: flex;
    align-items: center;
    .text-body {
        width: 70%;

        .text-elipse {
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.upload-file {
    width: 60%;
}
.col-upload {
    display: flex;
    justify-content: space-around;
    width: 72%;
}

.col-progress-date {
    width: 50%;
}

.col-release {
    padding: 0px 30px 0px;
}
.scroll-body {
    overflow-y: scroll;
    height: 60vh;
}

.circle-icon1 {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.circle-icon {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled-row {
    align-items: center;
    background-color: #e8eaed;
    // Uncomment below - If row needs to disable
    /* pointer-events: none; */
}

// Status

.tagsBody {
    background: transparent;

    .tagsBodyPanel {
        border-radius: 4px !important;
        border-bottom: none;
        background-color: #f3f8ff;

        & + .tagsBodyPanel {
            margin-top: 20px;
        }
    }
}

b {
    color: grey;
    font-family: sans-serif;
}

.tagComn {
    background-color: #f3f8ff;
}

.tags {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    .icon {
        display: flex;
    }
}
.tag-body {
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-body {
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
}

.approval-status-body {
    background-color: #deecff;
    // box-shadow: rgba(99, 160, 252, 0.16) 0px 1px 4px;
    overflow-y: scroll;
    // scroll:hidden;
    height: 300px;
}
.circle-icon {
    // border: 3px solid #b9bdc2;
    background-color: #c7cacf;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkBox-input {
    -webkit-appearance: none;
    visibility: hidden;
    display: none;
}

.quick-chat {
    position: fixed;
    top: 0;
    width: 405px;
    right: -405px;
    z-index: 999;
    transition: 0.5s ease;
    transform: translateX(-405px);

    .chat-box {

        .chat-content {
            max-height: unset;
            overflow-x: hidden;

            .chat-message {
                width: 65%;
            }
        }
    }

    .chat-sidebar {
       height: 100%;
       max-height: unset;
    }
}

.show {
    position: absolute;
    right: 405px;
    bottom: 300px;
    font-size: 25px;
    border-radius: 3px;
    color: #000;
    padding: 3px 8px;
    cursor: pointer;
    background: #dee1e3;
    border: 2px solid #dee1e3;
    border-right: none;
}

#hide {
    background: #c6cfff;
}

#check:checked ~ .quick-chat {
    transform: translateX(0);
}

#check:checked ~ .quick-chat #hide {
    display: none;
}

.comet-chat-body {
    height: 100vh;
    background-color: white;
    position: relative;

    .demo-package-chat-header {
        border-bottom: 1px solid #d7d7dd;
        padding: 19px 15px;
        
        h1 {
            margin: 0;
        }
    }

    .demo-package-chat-actions {
        position: absolute;
        bottom: 0;
        border-top: 1px solid #d7d7d7;
        padding: 20px 15px;
        left: 0;
        right: 0;
        
    }
}

.package-card {
    background-color: #f9f9f9;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: 1px solid #d7d7d7;

    h4 {
        font-size: 1.2rem;
        font-weight: 600;
        color: #555;
        text-transform: uppercase;
        margin: 0;
    }

    p {
        margin: 0;
        font-size: 1.2rem;
        color: #555;
    }
}

.info-icon {
    color: #dee1e3;
    font-size: 25px;
    width: 100%;
    height: 100%;
    :hover {
        color: #c6cfff;
    }
}

.modal-img {
    width: 552px;
    height: 583px;
    object-fit: contain;
}

.flow-chart-body .ant-modal-content {
    width: 600px;
}

.flow-chart-body .ant-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.packages-tabs {
    border: 1px solid #d7d7d7;
    margin-right: 15px;
    border-radius: 20px;
    overflow: hidden;

    .packages-tab-menu {
        padding: 5px 25px;
        display: inline-block;
        border-left: 1px solid #d7d7d7;
        cursor: pointer;

        &:first-child {
            border-left: 0;
        }

        &.active {
            background-color: #1890ff;
            color: #fff;
        }

        &.packages-tab-menu-disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.4;
        }
    }
}

