.singline-marker-container {
    color: #fff;
    border-radius: 2px;
}

.leaflet-div-icon {
    background: none;
    border: none;
}

.singline-marker-container p {
    margin: 0;
    font-size: 13px;
    text-align: center;
    margin-top: 3px;
}

.right-arrow-align {
    display: grid;
    width: 30px;
    height: calc(105vh - 240px);
    cursor: pointer;
    position: absolute;
    right: 5px;
    z-index: 15;
    /* top: 220px; */
    align-items: center;
}
.left-arrow-align {
    display: grid;
    width: 30px;
    height: calc(105vh - 240px);
    cursor: pointer;
    position: absolute;
    left: 5px;
    z-index: 15;
    /* top: 220px; */
    align-items: center;
}

/***************************
  * SCROLLBARS
  ***************************/
.scrollbar,
.scrollbar .up,
.scrollbar .down,
.scrollbar .left,
.scrollbar .right,
.vertical-scrollbar .track .horizontal-scrollbar .track,
.scrollbar .track .dragBar {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    outline: none;
}

.scrollbar {
    position: absolute;
    background: #fff;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
    filter: alpha(opacity=50);
}

.scrollbar:hover {
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
    filter: alpha(opacity=100);
}

.vertical-scrollbar {
    right: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    height: auto;
}

.vertical-scrollbar:hover {
    width: 20px;
}

.vertical-scrollbar.visible {
    visibility: visible;
}

.horizontal-scrollbar {
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: 15px;
}

.horizontal-scrollbar:hover {
    height: 20px;
}

.horizontal-scrollbar.visible {
    visibility: visible;
}

.scrollbar .up,
.scrollbar .down,
.scrollbar .left,
.scrollbar .right {
    width: 20px;
    height: 20px;
    position: absolute;
    background: #e6e6e6;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 70%;
}

.scrollbar .up:hover,
.scrollbar .down:hover,
.scrollbar .left:hover,
.scrollbar .right:hover,
.scrollbar .track .dragBar:hover {
    background-color: #3a9d82;
}

.scrollbar .up {
    left: 0;
    top: 0;
    /* background-image: url("../images/icons/scrollbar-arrow-up.svg"); */
    background-position: center center;
    width: 100%;
}

.scrollbar .down {
    left: 0;
    bottom: 20px;
    /* background-image: url("../images/icons/scrollbar-arrow-down.svg"); */
    background-position: center center;
    width: 100%;
}

.scrollbar .left {
    left: 0;
    bottom: 0;
    /* background-image: url("../images/icons/scrollbar-arrow-left.svg"); */
    background-position: center center;
    height: 100%;
}

.scrollbar .right {
    right: 20px;
    bottom: 0;
    /* background-image: url("./assets/images/scrollbar-arrow-right.svg"); */
    background-position: center center;
    height: 100%;
}

.vertical-scrollbar .track {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 20px;
    bottom: 40px;
    background: #f1f1f1;
    overflow: hidden;
}

.horizontal-scrollbar .track {
    position: absolute;
    width: auto;
    height: 100%;
    left: 20px;
    right: 40px;
    bottom: 0px;
    background: #f8f8f8;
    overflow: hidden;
}

.scrollbar .track .dragBar {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #3a9d82;
    cursor: pointer;
    z-index: 10;
}

.vertical-scrollbar .track .dragBar {
    height: 33%;
    top: 33%;
    width: 100%;
}

.horizontal-scrollbar .track .dragBar {
    width: 33%;
    left: 33%;
    height: 100%;
}

/***************************
  * RESPONSIVE STATES
  ***************************/

@media screen and (max-width: 1300px) {
    .maps-container {
        height: 500px;
        padding-bottom: 0;
    }

    .maps-container-inner {
        height: 0;
        padding-bottom: 32.5%;
    }
}

@media screen and (max-width: 1024px) {
    .maps-container {
        width: 100%;
        height: 484px;
        padding-bottom: 0;
        margin-top: 54px;
    }

    .map {
        margin-top: 0;
    }
}

.tags-pop-over .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 4%), 0 6px 26px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 21%);
}

.tags-pop-over .ant-popover-title {
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding: 10px 16px;
}

.tag-content-body {
    background-color: #fff;
}

.tag-content-body h4 {
    font-weight: 600;
}

.tag-content-body h4 {
    font-weight: 600;
}

.leaflet-popup-content p {
    margin: 0 !important;
    margin-bottom: 1rem !important;
}

.tag-content-body .mb-0 {
    margin-bottom: 0 !important;
}

.leaflet-control-attribution {
    display: none !important;
}

@media screen and (max-width: 480px) {
    .maps-container {
        height: 300px;
    }
}
