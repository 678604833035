@import '../variables.scss';

.select {
    border: 1px solid #d9d9d9;
    padding: 12px;

    h5 {
        border-bottom: 1px solid #d9d9d9;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0px 15px;
        padding-bottom: 9px;
        margin-right: -12px;
        margin-left: -12px;
    }
    .scroll {
        overflow: auto;
        height: 100%;
        max-height: 271px;
        min-height: 271px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.tag {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    margin-bottom: 5px;
    cursor: pointer;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tag-dragging {
    padding: 12px;
    border-radius: 4px;
    border: 2px solid $primary-color !important;
    color: $primary-color;
    margin-bottom: 5px;
}

.empty-box {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
    color: #80808099;
    font-size: 18px;
}

.right-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 49px;
    left: 0;
    right: 0;
    width: 50px;
    text-align: center;
    font-size: 30px;
    color: #777;
}
