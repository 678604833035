.video-container {
    margin-top: 3rem;
}

.upload-btn-container {
    position: absolute;
    top: 1rem;
    right: 2rem;

    .upload-btn {
        cursor: pointer;
        width: 10rem;
        height: 34px;
        border: none;
        border-radius: 32px;

        &:after {
            padding: 5px;
            content: 'Upload';
            font-size: 15px;
            cursor: pointer;
            position: absolute;
            text-align: center;
            top: 0;
            left: 0;
            border: none;
            background-color: #16aaff;
            width: 100%;
            height: 34px;
            line-height: 20px;
            color: rgb(253, 245, 245);
            font-weight: 500;
        }
    }
}
