
.property {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .title {
        font-weight: bold;
        font-size: 14px;
    }

    .value {
        font-size: 14px;
        font-weight: normal;
        margin-left: 5px;
    }
}

.property-nextline {
    display: block;
    align-items: center;
}

.verticial-form-display {
    .property { 
        .value {
            display: block;
            margin-left: 0px;
        }
    }
}