.container {
    max-width: 1140px;
    margin: auto;
    padding: 0 25px;
}
.header-container {
    background-color: white;
    padding: 0;
}

.single-line-logo {
    display: flex;
}
.nav {
    display: flex;
    align-items: center;
    margin-left: 18px;
}
.single-line-logo a img {
    height: 30px;
}
.single-line-logo .nav > ul {
    display: flex;
    border-width: 0;
    align-items: center;
}
.single-line-logo .nav > ul > li {
    margin-bottom: 0 !important;
    margin: 0 12px;
    height: auto;
    padding: 12px 16px;
    border-bottom: 2px solid #fff;
}
.single-line-logo .nav > ul > li > span > a {
    color: grey;
}
.single-line-logo .nav > ul > li:hover {
    border-bottom: 2px solid #515151;
}
.single-line-logo .nav > ul > li > span > a:hover {
    color: #515151;
}

.phone-number {
    display: flex;
    justify-content: space-between;
}
.phone-number a {
    display: flex;
    align-items: center;
    color: grey;
}
.phone-number a:hover {
    color: #515151;
}
.phone-number a img {
    height: 14px;
    margin-right: 8px;
}
.phone-number a p {
    margin: 0;
    font-size: 14px;
}
.login-btn {
    color: gray;
    font-size: 14px;
    font-weight: bold;
}
.login-btn:hover {
    color: #515151;
}

.get-started-btn {
    border-radius: 5px;
    font-size: 14px;
    color: white;
    background: #1890ff;
    height: 44px;
    font-weight: bold;
}
.get-started-btn:hover {
    background: #1890ff;
    color: #fff;
}
