@import '../../../../app/assets/styles/variables.scss';

.construction-row {
    // padding: 65px 0px;
    // background-color: #253232;
    background-image: url('../../../assets/images/construction_bg.gif');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
}
.construction-shadow {
    position: relative;
    top: 0;
    background-color: #253232d9;
}

.container {
    max-width: 1140px;
    margin: auto;
    padding: 0 25px;
}

.construction-left-box {
    padding: 58px;
    padding-left: 0;
    h2 {
        display: inline-block;
        border-bottom: 2px solid #4cb08c;
        color: beige;
    }
    h1 {
        font-size: 2.75rem;
        font-weight: 900;
        color: beige;
        line-height: 48px;
    }
    p {
        color: beige;
    }
}

.construction-right-box {
    img {
        height: 510px;
        float: right;
    }
}
.schedule-get-trail-btn {
    font-size: 14px;
    height: auto;
    padding: 16px 30px;
    font-weight: bold;
    border-radius: 5px;
    color: #4cb08c;
    border-color: #4cb08c;
    background-color: transparent;
    &:hover {
        background: #4cb08c !important;
        color: white;
        border-color: #4cb08c;
    }
}
// .schedule-get-trail-btn:hover {
// }
