.auth-container {
    .auth-image {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: top right;
    }

    .auth-form-container {
        padding: 50px 80px;

        .auth-form-header {
            margin-bottom: 50px;

            h3 {
                font-size: 41px;
                font-weight: 600;
                color: #333;
                margin: 0;
            }

            p {
                font-size: 24px;
                color: #8a8a8a;
                font-weight: 500;
                margin: 0;
            }
        }

        .ant-input {
            height: 45px;
            color: #777;
            font-size: 15px;
            border-radius: 5px;
            border-width: 2px;
            border-width: 2px !important;
        }

        .ant-form-item-control-input {
            max-height: 45px;
        }

        .ant-form-item-label > label {
            font-size: 17px;
            color: #8e8e8e;
            font-weight: 500;
        }

        .ant-input-affix-wrapper {
            padding-top: 0;
            padding-bottom: 0;
            border-radius: 5px;
            border-width: 2px !important;

            .ant-input {
                height: 43px;
            }
        }

        .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
            box-shadow: none;
            border-width: 2px;
        }

        .ant-input:focus, .ant-input-focused {
            box-shadow: none;
            border-width: 2px !important;
        }

        .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, 
        .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
            border-width: 1px !important;
        }
    }
}
