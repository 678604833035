.digital-row {
    padding: 65px 0px;
    background-image: url('../../../assets/images/digital_bg.webp');
    background-size: cover;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}
.digital-shadow {
    position: relative;
    top: 0;

    background-color: #ffffffeb;
    margin-top: -65px;
    margin-bottom: -65px;
}

.container {
    max-width: 1140px;
    margin: auto;
    padding: 0 25px;
}

.left-box {
    padding: 0;
    img {
        height: 524px;
    }
    .smartphone-img {
        width: 590px;
        height: 440px;
        margin-left: -60px;
    }
}

.right-box {
    padding: 30px 65px;
    text-align: left;
    h1 {
        font-size: 2.75rem;
        font-weight: 900;
        line-height: 46px;
    }
    p {
        font-size: 1rem;
    }
    .p-1 {
        font-size: 1.125rem;
    }
}
