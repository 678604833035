.become-digital-row {
    padding: 40px 0;
    background-color: #253232;
}

.become-digital-left {
    h1 {
        color: beige;
        font-size: 2rem;
        margin: 0;
    }
}
.become-digital-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.become-digital-trail-btn {
    font-size: 14px;
    height: auto;
    padding: 16px 30px;
    font-weight: bold;
    border-radius: 5px;
    color: #4cb08c;
    border-color: #4cb08c;
    background-color: transparent;
    margin-right: 45px;
    &:hover {
        background: #4cb08c !important;
        color: white;
        border-color: #4cb08c;
    }
}
