@import '../../../assets/styles/variables.scss';

.project-heading {
    color: $primary-color;
    font-size: 27px;
}

.container-height {
    height: calc(100vh - 213px);
}

.ant-select-selector {
    button {
        display: none;
    }
}