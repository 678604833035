.setting-main {
    min-height: 83vh;

    .card-container {
        margin-top: 12px;
        .tabs-sidebar {
            .ant-tabs-nav {
                width: 200px;

                .ant-tabs-tab {
                    font-size: 18px;
                    font-weight: 490;
                }
            }
        }
    }
    .side-heading {
        h1 {
            display: flex;
            font-size: 170%;
            font-weight: 500;
            letter-spacing: 0.25px;
            line-height: normal;
            margin: 6px;
            padding: 14px;
        }
    }
    .setting-card-body {
        width: 50%;
        margin: 10px;
        padding: 25px;
        margin-left: 120px;
        border-radius: 10px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }

    .submit-button {
        border-radius: 18px;
        width: 90px;
        height: 40px;
    }

    .equipment-body {
        line-height: 3;
    }

    .checkbox-body {
        display: flex;
        flex-direction: column;
    }
}
.notification-header {
    display: block;
    margin-left: 110px;

    h2 {
        font-family: Roboto, 'Segoe UI', Tahoma, sans-serif;
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 0.25px;
        margin-bottom: 12px;
        padding-bottom: 4px;
        padding-top: 8px;
        margin-left: 12px;
    }
}

.on-the-day-body {
    margin-top: 10px;
    margin-bottom: 10px;
}

.p-titles {
    font-size: 16px;
    font-family: Roboto, 'Segoe UI', Tahoma, sans-serif !important;
}

.submit-button-body {
    display: flex;
    justify-content: flex-end;
}

.inputNumber-body {
    width: 50px;
}
.marginL {
    margin-left: 8px;
}
