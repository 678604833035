.bid-document-modal-container {
    .upload-container {
        display: flex;
        justify-content: center;
    }
}

.ant-upload-text {
    margin: 0 0 4px !important;
    color: rgb(112 112 112 / 85%) !important;
    font-size: 15px !important;
}

.file_name_link {
    cursor: pointer;
    :hover {
        color: #1890ff;
        text-decoration: underline;
    }
}
