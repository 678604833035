.footer-bottom-row {
    padding: 30px 0;
    background-color: #e8ede8;
    border-top: 1px solid #212529;
}

.protection {
    display: flex;
    align-items: center;
    p {
        margin: 0;
        color: #253232;
        font-size: 0.75rem;
        font-weight: 300;
    }
    a {
        margin: 0px 16px;
        color: #253232;
        font-size: 0.75rem;
        font-weight: 300;
        &:hover {
            color: #4cb08c;
        }
    }
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons {
    // display: flex;
    align-items: center;

    a {
        color: #253232;
        display: inline-block;
        margin-right: 16px;
        &:hover {
            color: #4cb08c;
        }
    }
}

.stors {
    .app-store {
        width: 125px;
        height: 40px;
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../../assets/images/app_store_img.svg');
    }
    .play-store {
        width: 137px;
        height: 40px;
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../../assets/images/play_store_img.svg');
    }
}
