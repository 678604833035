.feature-need-row {
    padding: 55px 0px;
    // background-color: #e8ede8;
}

.feature-option-box {
    width: 208px;

    border: 2px solid #a2c617;
    border-radius: 5px;
    padding: 16px;
    cursor: pointer;
    margin-bottom: 18px;
    h3 {
        font-size: 1rem;
        font-weight: 600;
        color: #253232;
        line-height: 25px;
    }
}

.feature-option-box-avtive {
    width: 208px;
    border: 2px solid #a2c617;
    border-radius: 5px;
    padding: 16px;
    color: white;
    background-color: #a2c617;
    cursor: pointer;
    margin-bottom: 18px;
    h3 {
        font-size: 1rem;
        font-weight: 600;
        color: white;
        line-height: 25px;
    }
    p {
        color: white !important;
    }
}
.feature-content {
    h1 {
        font-size: 2.2rem;
        font-weight: 600;
        color: #253232;
        text-align: center;
    }
    p {
        color: #253232;
    }
}
.feature-images {
    display: flex;
    align-items: center;
    img {
        height: 530px;
    }
}
