.chatWrapper {
    width: 100%;
    height: 340px;
    background-color: #233044;
    position: absolute;
    padding: 0;
    top: -43px;
    left: 0;
    z-index: 999;
}

.popover-body {
    left: 15% !important;
    width: 70%;
}

.popover-body .ant-popover-inner-content {
    padding: 0px !important;
}

.modalBody .ant-modal-content {
    width: 700px;
    background-color: #f1f5f8;
    border-radius: 20px;
}
