.chat-widget {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99;
    margin: 40px;

    .chat-display {
        position: relative;
        height: 480px;
        width: 320px;
        border-radius: 10px;
        background-color: white;
        border: 1px solid rgb(190, 189, 189);
        overflow: hidden;

        .chat {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin: 5px 10px;
            flex: 1;
            max-height: 380px;
            overflow-y: scroll;

            .message-box {
                width: 100%;

                
                .messages {
                    
                    .chat-message-box {
                        display: flex;
            
                        &.justify-end {
                            justify-content: end;
                        }
                    }
                    
                    .chat-message {
                        display: flex;
                        padding: 2px 0;

                        

                        .text-message {
                            background-color: #ececec;
                            font-size: 12px;
                            margin-left: 8px;
                            padding: 4px;
                            border-radius: 4px;
                            width: 160px;

                            .time {
                                font-size: 9px;
                            }
                        }
                    }
                }
            }

           
        }

        .chat-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 420px;

            ::-webkit-scrollbar {
                width: 0px;
                background: transparent; /* make scrollbar transparent */
            }

            .message-input {
                height: 40px;
                margin: 5px 5px 0 !important;
                width: 95%;
            }
        }

        .contact-list {
            display: flex;
            flex-direction: column;
            margin: 5px 10px;
            height: 100%;
            overflow-x: scroll;
            padding-bottom: 40px;

            .contact-item {
                padding: 10px 10px;
                display: flex;
                flex-direction: row;
                cursor: pointer;

                &:hover {
                    background-color: #e0f3ff;
                }

                .ant-avatar {
                    margin-right: 10px;
                }
            }
            
        }

        .chat-header {
            font-size: 18px;
            font-weight: bold;
            padding: 10px;
            text-align: center;

            .ant-badge-status-dot {
                margin-left: 10px;
                width: 10px;
                height: 10px;
                top: -3px;
            }

            .remove-contact {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

        .search-bar {
            margin: 5px 10px;
        }

        .anticon {
            width: 100%;
            text-align: left;
            display: flex;
            align-items: flex-end;
        }
    }

    .chat-icon {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
    }
}