@import '../../../assets/styles/variables.scss';

.task-inbox-actions {
    border-bottom: 1px solid #d7d7d7;

    .inbox-search {
        padding-left: 32px;
        height: 38px;
        font-size: 14px;
        color: #555;
        background: url('../../images/search-icon.png') no-repeat center left 10px;
        background-size: 14px;
        border: 0;
        border-radius: 4px;
        width: 100%;
        padding-right: 10px;
        outline: none !important;
    }

    .ant-select {
        color: #555;
        box-shadow: none !important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-bottom: 0;
        height: 38px;
        border-top: 0;
        border-right: 0;
        box-shadow: none !important;
        border-color: #d7d7d7 !important;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 38px;
    }
}

.table-filter {
    display: flex;
    flex-direction: row;
    flex: 1;

    .label {
        font-size: 14px;
        font-weight: 600;
        margin-right: 20px;
    }

    .ant-tag{
        height: 24px;

        .filter-name {
            text-transform: capitalize;
            font-size: 12px;
            margin-right: 10px;
        }
        .filter-value {

        }
    }
}

.task-inbox-list {
    height: calc(100vh - 340px);
    overflow-y: auto;

    
    @media (min-width: 1600px) {
        max-height: 1000px;
    }

    .task-notification {
        width: 100%;

        article {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }
    }

    .task-active {
        background-color: #e0f3ff;
    }

    ul {
        li {
            padding: 10px 15px;
            border-bottom: 1px solid #d7d7d7 !important;
            cursor: pointer;
            border-left: 5px solid transparent;
            position: relative;

            .task-inbox-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 3px;
                align-items: center;

                h4 {
                    font-weight: 600;
                    margin: 0;
                    color: #555;
                    font-size: 13px;
                }
                .actions {
                    .notification-time {
                        font-size: 12px;
                        color: #777;
                    }
                    button {
                        font-size: 12px;
                        height: 22px;
                        box-shadow: none;

                        &:hover {
                            background: none !important;
                            color: #16aaff !important;
                            border-color: #16aaff;
                        }
                    }
                }
            }

            p {
                margin: 0;
                color: #555;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                max-width: 220px;
            }

            &:hover {
                background-color: #e0f3ff;
            }

            &#active {
                border-left: 5px solid $primary-color;
                background-color: #e0f3ff;

                &.unread::before {
                    display: none;
                }
            }

            &.read {
                // background-color: #ededed;
            }

            &.unread::before {
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $primary-color;
                left: 3px;
                top: 16px;
            }
        }
    }
}

.row-body {
    .group-list-msg-body {
        width: 100%;
        min-width: 790px;
        height: 600px;
        overflow-x: hidden;
        border: none;
    }
}

.notifications-content {
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (min-width: 1400px) {
        max-height: 1040px;
        height: 100%;
    }
    
    @media (min-width: 1600px) {
        max-height: 1040px;
        height: 100%;
    }

    .notifications-body {
        display: flex;
        overflow-y: auto;
        padding-right: 15px;
        flex: 1;
        flex-direction: column;

        .notification {
            display: flex;
            border-radius: 4px;
            padding: 15px;
            margin-bottom: 10px;
            border: 1px solid #d7d7d7;
            flex-direction: column;
            margin-left: 10px;

            p {
                margin-bottom: 10px;
                display: block;
            }

            .date {
                font-size: 13px;
                color: #555;
            }
        }
    }
}

.active-inbox-tab {
    background-color: #deecff;
}

.custom-goto-package-button {
    position: absolute;
    top: -47px;
}

.sub-chart-heading {
    margin-left: 20px;
    margin-top: 8px;
    border-bottom: 2px solid #16aaff;
    color: #555 !important;
}

.gantt-chart-card {
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    // margin-bottom: 10px;
    // border-radius: 12px;
    // padding: 18px 18px 0 0;
}