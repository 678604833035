.get-to-know-row {
    padding: 80px 0;
    background-color: #253232;
}

.get-to-know-content {
    h1 {
        font-size: 2.1rem;
        color: beige;
        // margin-bottom: 42px;
    }
}
.get-to-know-box {
    display: flex;
    align-items: center;
    span {
        font-size: 52px;
        margin-bottom: 12px;
        color: #4cb08c;
    }
    p {
        margin-bottom: 0;
        margin-left: 18px;
        font-size: 18px;
        color: beige;
    }
}
.get-to-know-btns {
    display: flex;
    justify-content: center;
    margin-top: 75px;
}

.get-to-know-trail-btn {
    font-size: 14px;
    height: auto;
    padding: 16px 30px;
    font-weight: bold;
    border-radius: 5px;
    color: #4cb08c;
    border-color: #4cb08c;
    background-color: transparent;
    margin-right: 45px;
    &:hover {
        background: #4cb08c !important;
        color: white;
        border-color: #4cb08c;
    }
}
.get-to-know-free-demo-btn {
    font-size: 14px;
    height: auto;
    padding: 16px 30px;
    font-weight: bold;
    border-radius: 5px;
    color: beige;
    border-color: #4cb08c;
    background: #4cb08c !important;
    margin-right: 45px;
    &:hover {
        background: #4cb08c !important;
        color: white;
        border-color: #4cb08c;
    }
}
