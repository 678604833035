.content-row {
    border-top: 1px solid #ccc;
    margin-top: 2px;
    background-image: url('../../../assets/images/content_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
}
.content-shadow {
    position: relative;
    top: 0;
    /* border: 2px solid red; */
    background-color: rgb(255 255 255 / 72%);
}
.left-content {
    padding-top: 75px;
}
.left-content h1 {
    font-size: 55px;
    line-height: 57px;
}

.right-content {
    text-align: center;
}
.right-content img {
    height: 605px;
}

.get-trail-btn svg {
    margin-left: 12px;
}
.get-trail-btn {
    font-size: 14px;
    height: auto;
    padding: 16px 30px;
    font-weight: bold;
    border-radius: 5px;
    color: #1890ff;
    border-color: #1890ff;
    /* background-color: #1890ff; */
}

.get-trail-btn:hover {
    background: #1890ff;
    color: white;
    border-color: white;
}
