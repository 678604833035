.what-you-can-row {
    padding: 75px 0px;
    background-color: #e8ede8;
}

.what-you-can-content {
    h1 {
        font-size: 2.2rem;
        font-weight: 6oo;
        color: #253232;
    }
    p {
        color: #253232;
    }
}

.construction-btn {
    background-color: beige;
    // transition: 2s background-color;
    transition: all;
    margin: 20px;
    padding: 14px;
    border-radius: 8px;
    text-align: center;
    margin-left: 0;
    margin-bottom: 0;
    cursor: pointer;
    h2 {
        margin-bottom: 0;
        color: #253232;
        font-weight: normal;
        font-size: 16px;
    }
}
.construction-btn:hover {
    background-color: #4cb08c;
    transition: background-color 500ms linear;
    h2 {
        color: white;
    }
}
