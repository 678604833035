.parentBody {
    .container {
        display: flex;
        justify-content: center;

        .icon {
            position: absolute;
            font-size: 5rem;
            color: #1890ff;
            top: -15px;
        }
        .headingBody {
            h2 {
                text-align: center;
                margin-top: 50px;
                font-size: 30px;
                font-family: 'Roboto Condensed', sans-serif;
                font-weight: 600;
            }
            p {
                text-align: center;
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 14px;
            }
        }
    }
}
.buttonSend {
    position: absolute;
    border: none;
    background-color: #1890ff;
    width: 150px;
    height: 54px;
    font-weight: 500;
    border-radius: 25px;
    right: 38%;
    top: 4rem;
}
