.filters-sidebar {
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 127px);
    padding: 20px;
    box-shadow: 7px 0 27px rgba(0, 0, 0, 0.05);

    .sidebar-heading {
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        h4,
        a {
            font-size: 20px;
            color: #555;

            .anticon-down {
                margin-left: 10px !important;
            }
        }
    }
}

