.cardBody {
    background-color: #f3f8ff;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 25px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    p {
        font-weight: 500;
    }
}

.cardBodyBottom {
    background-color: #deecff;
    border-radius: 25px;

    .cardBottom {
        display: flex;
        justify-content: space-between;
    }
}

.lightColor {
    background-color: #f3f8ff;
    border-radius: 25px;
}

.darkColor {
    background-color: #deecff;
}
