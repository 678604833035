
.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.event-box {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    color: #474749;
    border-radius: 6px;
    padding: 0px 10px;
    height: 30px;
    font-weight: 500;
    font-size: 12px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    .anticon {
        color: green;
        font-size: 17px;
        margin-right: 10px;
        vertical-align: middle;
    }
}

.text-ellipsis {
    text-decoration: none;
    white-space: nowrap;
    width: 97%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-heading {
    margin: 0 !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
    text-align: center;
    padding: 0 !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        background-color: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #c8c6c6;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #c8c6c6;
    }
}

.calender_container {
    .headline_year {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0px;
        background-color: #fff;
    }

    .ant-radio-button-wrapper-checked:not(
            [class*=' ant-radio-button-wrapper-disabled']
        ).ant-radio-button-wrapper:first-child {
        display: none;
    }

    .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
        display: none;
    }

    .card_container {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }

    .calender_view {
        background-color: #fff;
    }

    .detail_view {
        background-color: #fff;
    }

    .list_data {
        margin: 10px;
        li {
            margin: 10px 0px;
            border: 1px solid rgb(196, 193, 193);
            border-radius: 10px;
            padding: 10px;
        }
    }
}

.link_btn {
    color: #16aaff;
    cursor: pointer;
}

.ant-popover-title {
    text-align: center;
    text-transform: uppercase;
}

.event_tag {
    height: 25px;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 10px 0;
  
    .calendar-nav {
      margin: 0 0px;
    }
  
    .nav-button {
      background-color: #f0f0f0;
      border: none;
      border-radius: 4px;
      padding: 6px 10px;
      font-size: 18px;
      cursor: pointer;
  
      &:hover {
        background-color: #e0e0e0;
      }
    }
  
    .calendar-selects {
      display: flex;
      align-items: center;
  
      .month-select,
      .year-select {
        font-size: 18px;
        padding: 6px 10px;
      }
    }
  }