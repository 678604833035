.card-main {
    margin: 10px;
    backdrop-filter: blur(5);
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    .card-header {
        display: flex;
        background: #f4f4f4;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        .avatar {
            margin-top: 10px;
        }
        .heading-name {
            width: 100%;
            h3 {
                margin-left: 10px;
                margin-top: 15px;
            }
        }
    }
    .sub-heading {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        margin-bottom: 10px;

        .sub-heading-content {
            display: flex;
            justify-content: space-between;
            color: grey;
            border-top: 1px solid rgba(230, 228, 228, 0.817);
            border-bottom: 1px solid rgba(230, 228, 228, 0.817);
            padding: 6px;

            .sub-heading-number {
                color: #769fcd;
                font-weight: 600;
            }

            &:hover {
                background-color: #daeffb;
            }
        }
    }
}
