.footer-row {
    padding: 45px 0;
    background-color: #e8ede8;
}

.contact-section {
    h6 {
        font-size: 0.75rem;
        font-weight: 700;
    }
    li {
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        color: #253232;
        margin-bottom: 6px;
        span {
            margin-right: 12px;
        }
    }
}

.section {
    h6 {
        font-size: 0.75rem;
        font-weight: 700;
    }
    li {
        margin-bottom: 6px;
    }
    a {
        font-size: 0.75rem;
        display: inline-block;
        align-items: center;
        color: #253232;
        &:hover {
            color: black;
        }
    }
}
