.dashboard-container {
    .ant-card {
        border-radius: 6px;
        padding: 14px;

        .inner-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            color: beige;

            h4 {
                font-weight: 500;
                margin: 0;
                color: beige;
                font-weight: 600;
                text-transform: uppercase;
            }
        }

        h3 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 0;
            color: beige;
            margin: 10px 0;
        }

        .ant-progress {
            font-size: 12px;

            .ant-progress-text {
                color: white;
                font-weight: 600;
            }

            .ant-progress-bg {
                margin: 1px;
            }
        }

        .ant-card-body {
            padding: 0;
        }
    }
}

.summary-heading {
    margin-bottom: 0;
    border-bottom: none;
}

.chart-card {
    padding: 24px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);

    h4 {
        margin: -6px -24px 14px -24px;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 4px;
        padding: 0px 24px 6px 24px;
        border-bottom: 1px solid #43434826;
    }
}
