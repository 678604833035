.task-details-content{
    .task-heading {
        padding: 20px;
        font-size: 28px;
        font-weight: bold;
    }

    .task-details {
        .ant-col {
            padding: 20px;

            .property {
                .title {
                    font-weight: bold;
                    font-size: 16px;
                }
                .value {
                    margin-top: 10px;
                }
            }
        }
    }
}

.ant-form {
    .ant-form-item {
    }
}