.professional-row {
    padding: 55px 0px;
    background-color: #e8ede8;
}

.professionals-h {
    font-size: 2.3rem;
    font-weight: 900;
    margin-bottom: 45px;
    text-align: center;
}
.pro-row-box {
    .ant-col {
        padding: 0px 20px;
        text-align: center;
        img {
            height: 60px;
        }
        h3 {
            font-size: 1.2rem;
            font-weight: 600;
            // line-height: 37px;
            margin-top: 20px;
            color: #253232;
        }
    }
}
